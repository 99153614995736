<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img width="200" height="50" src="@/assets/images/logo.png" alt="">
        </b-link>
        <b-card-title class="mb-1">
          {{ $t('registerasanew') }}
        </b-card-title>
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="SendCheckCode">
            <!-- PhoneNumber -->
            <b-form-group :label="$t('PhoneNumber')" label-for="PhoneNumber">
              <validation-provider #default="{ errors }" name="PhoneNumber" rules="required|min:11">
                <b-form-input id="PhoneNumber" v-model="PhoneNumber" :state="errors.length > 0 ? false : null"
                  name="register-PhoneNumber" placeholder="01010101010" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <b-button variant="primary" block type="submit" :disabled="!validateForm">
              {{ $t('Signup') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
      <!-- @CloseSeccussPopup="CloseSeccussPopup" @closePop="this.$router.push('/pages/login')" -->
      <!-- <vs-popup title="" :active.sync="ShowSuccessPopup">
        <SuccessPopup :Message="$t('RegisterSuccsessMessage')" v-if="ShowSuccessPopup"
        @CloseSeccussPopup="CloseSeccussPopup" :PhoneNumber="PhoneNumber"
          ></SuccessPopup>
      </vs-popup> -->
    </div>
  </div>
</template>

<script>

import { domain } from "@/gloabelConstant.js"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import {
  BButton, BCard, BCardText, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SuccessPopup from "./SuccessPopup.vue"
export default {
  components: {
    VuexyLogo,
    SuccessPopup,
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      baseURL: domain,
      PhoneNumber: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validateForm() {
      return (
        this.PhoneNumber != ""
      );
    },
  },
  methods: {

    SendCheckCode() {
      // If form is not validated or user is already login return
      if (!this.validateForm) return;
      debugger
      const payload = {
        userDetails: {
          PhoneNumber: this.PhoneNumber,
        },
      };
      debugger;
      this.$vs.loading();
      // this.$router.push({
      //   name: "CheckOTP",
      //   params: { PhoneNumber: this.PhoneNumber },
      // });
      //this.$router.push("/CheckOTP/" + this.PhoneNumber);

      this.$store
        .dispatch("auth/SendOTP", payload)
        .then((res) => {
          debugger;
          this.$vs.loading.close();
          this.$router.push({
            name: "CheckOTP", params: { Id: this.PhoneNumber }
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "regstration Attempt",
            text: "Fail in send OTP Code",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          console.log(error);
        });

    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
.material-icons {
  font-family: "Material Icons" !important;
}

.material-icons :lang(en) {
  font-family: unset;
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
  width: 50%;
}

li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>